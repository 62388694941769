<template>
  <div class="container">
    <div class="header">
      <div class="title">
        <img :src="basicConfig.Logo" alt="" />
      </div>
      <!-- <div class="lang" @click="showLanguage = true"></div> -->
    </div>
    <div class="swipe-box">
      <van-swipe
        ref="swiper"
        class="swipe-container"
        :autoplay="3000"
        indicator-color="#ccc"
      >
        <van-swipe-item
          v-for="(item, index) in rotationPics"
          :key="index"
          @click="JumpWeb(item.JumpUrl)"
        >
          <img class="bg" :src="item.ImgUrl" alt="" />
        </van-swipe-item>
      </van-swipe>
      <div class="swiper-btn" @click="toLogin" v-if="!isLogin">
        {{ $t('index').PleaseLogin }}
      </div>
      <div class="nav-pre" v-if="rotationPics.length > 1">
        <div class="pre" @click="handPre"></div>
        <div class="next" @click="handNext"></div>
      </div>
    </div>
    <Notice />
    <div class="grid">
      <ul>
        <li v-for="(item, index) in $t('index').grid" :key="index">
          <h2>{{ regexp(item.num) }}</h2>
          <h5>{{ regexp(item.text) }}</h5>
        </li>
      </ul>
    </div>
    <div style="padding: 0.1rem">
      <div class="product-box">
        <div class="product-list">
          <router-link
            tag="div"
            class="item"
            :to="{ name: 'Product', params: { id: vitem.Id } }"
            v-for="(vitem, vindex) in productList"
            :key="vindex"
          >
            <div class="top">{{ showProductName(vitem.Id) }}</div>
            <div class="bottom">
              <span class="now-price"
                >{{ showPrice(vitem.Price)
                }}<i class="arrow" :class="{ Rise: vitem.Rise }"></i
              ></span>
              <van-divider class="border" :hairline="false" />
              <div class="mini-text">
                <span>{{ vitem.difference }}</span>
                <span style="color: #10d4b0ed">{{ vitem.percentage }}%</span>
              </div>
            </div>
          </router-link>
        </div>
        <router-link tag="div" to="Product" class="btn">{{
          $t('index').btnTran
        }}</router-link>
      </div>
    </div>
    <div class="desc desc1">
      <div v-for="(item, index) in $t('index').product" :key="index">
        <img :src="item.imgUrl" height="56px" />
        <h4>{{ regexp(item.title) }}</h4>
        <p>
          {{ regexp(item.desc) }}
        </p>
      </div>
    </div>
    <div class="experience">
      <div class="content">
        <h2>{{ $t('index').experience.title }}</h2>
        <p>
          {{ $t('index').experience.desc }}
        </p>
        <div class="btn">
          {{ $t('index').experience.btn }}
        </div>
        <img src="@/assets/image_trade_anywhere_devices-768x512.png" alt="" />
      </div>
    </div>
    <div class="desc">
      <div
        class="p-15"
        v-for="(item, index) in $t('index').product2"
        :key="index"
      >
        <p>
          <img :src="item.imgUrl" height="56px" />
        </p>
        <h3>
          {{ item.title }}
        </h3>
        <p>
          {{ item.desc }}
        </p>
      </div>
    </div>
    <div class="area">
      {{ $t('index').area.title }}
      <br />
      {{ $t('index').area.desc }}
      <p>
        <img src="@/assets/map_CN_i.svg" alt="" />
      </p>
    </div>
    <div class="honor">
      <h2>{{ $t('index').honor.title }}</h2>
      <van-swipe
        ref="swiper2"
        class="swipe-container"
        :autoplay="3000"
        indicator-color="#ccc"
      >
        <van-swipe-item
          v-for="(item, index) in $t('index').honor.list"
          :key="index"
        >
          <div class="pic">
            <img :src="item.imgUrl" alt="" />
          </div>
          <div class="caption">
            <h4>{{ item.title }}</h4>
            <h5>
              {{ item.desc }}
              <br />
              {{ item.desc2 }}
            </h5>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <div class="pay">
      <h2>{{ $t('index').sysPay.title }}</h2>
      <p>
        {{ $t('index').sysPay.desc }}
      </p>
      <div class="content">
        <div class="list">
          <img src="@/assets/logo_visa.svg" width="93px" height="42px" alt="" />
        </div>
        <div class="list">
          <img
            src="@/assets/logo_mastercad.svg"
            width="54px"
            height="42px"
            alt=""
          />
        </div>
        <div class="list">
          <img
            src="@/assets/logo_neteller.svg"
            width="153px"
            height="42px"
            alt=""
          />
        </div>
        <div class="list">
          <img
            src="@/assets/logo_skrill.svg"
            width="153px"
            height="42px"
            alt=""
          />
        </div>
        <div class="list">
          <img
            src="@/assets/logo_bitwallet.svg"
            width="153px"
            height="42px"
            alt=""
          />
        </div>
        <div class="list">
          <img
            src="@/assets/logo_sticpay.svg"
            width="148px"
            height="42px"
            alt=""
          />
        </div>
        <div class="list">
          <img
            src="@/assets/logo_perfect_money.svg"
            width="153px"
            height="42px"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="black-bg">
      <div class="committed">
        <h3>{{ $t('index').transaction.title }}</h3>
        <p>
          {{ $t('index').transaction.desc
          }}<a>{{ $t('index').transaction.desca }}</a>
        </p>
        <div class="getting-started-parent" v-if="!isLogin">
          <div class="getting-started--child">
            <div class="getting-text--child">
              <h4>{{ $t('index').transaction.text }}</h4>
            </div>
            <p @click="toRegister">{{ $t('index').transaction.btn }}</p>
          </div>
        </div>
      </div>
      <div class="information">
        <p class="information-title">
          {{ regexp($t('index').brand.title) }}
        </p>
        <p class="information-con">
          {{ regexp($t('index').brand.text1) }}
        </p>
        <p class="information-con">
          {{ regexp($t('index').brand.text2) }}
        </p>
        <p class="information-con">
          {{ regexp($t('index').brand.text3) }}
        </p>
        <p class="information-con">
          {{ regexp($t('index').brand.text4) }}
        </p>
      </div>
      <div class="warn">
        <div class="content">
          {{ regexp($t('index').brand.text5)
          }}<span rel="noopener noreferrer nofollow">{{
            $t('index').brand.risk
          }}</span>
        </div>
      </div>
      <div class="information m-t0">
        <p class="information-con">
          {{ regexp($t('index').brand.text6) }}
        </p>
      </div>
      <div class="border-line"></div>
      <div class="copyright">
        <div class="icp">
          {{ $t('index').icp.text1 }} <span>{{ $t('index').icp.text2 }}</span>
        </div>
        <ul>
          <li>
            <span>{{ $t('index').icp.text3 }}</span>
          </li>
          <li>
            <span>{{ $t('index').icp.text4 }}</span>
          </li>
          <li>
            <span>{{ $t('index').icp.text5 }}</span>
          </li>
          <li>
            <span>{{ $t('index').icp.text6 }}</span>
          </li>
          <li>
            <span>{{ $t('index').icp.text7 }}</span>
          </li>
        </ul>
      </div>
    </div>
    <Language :showLanguage="showLanguage" @closePopup="showLanguage = false" />
    <TabBar bg="#f1f1f1" active="" />
  </div>
</template>
<script>
import { Token } from '@/utils/mm'
import TabBar from '@/components/tabBar'
import productApi from '@/api/product'
import Language from '@/components/language'
import userApi from '@/api/user'
import Notice from './notice'
import { EventBus } from '@/utils/event-bus'
import { mapGetters } from 'vuex'
export default {
  components: {
    Notice,
    Language,
    TabBar,
  },
  data() {
    return {
      isLogin: false,
      showLanguage: false,
      proList: [],
      productList: [],
      ProductNames: [],
      productBasic: [],
      rotationPics: [],
    }
  },
  computed: {
    ...mapGetters(['basicConfig', 'lang', 'connection']),
    lang() {
      this.change()
      return this.$store.getters.lang
    },
    companyName() {
      let name = ''
      let basicConfig = this.basicConfig
      if (this.lang === 'zh') {
        name = basicConfig.CompanyName
      } else if (this.lang === 'en') {
        name = basicConfig.CompanyNameEnglish
      } else if (this.lang === 'ft') {
        name = basicConfig.CompanyNameTraditional
      } else {
        name = basicConfig.CompanyNameJapanese
      }
      return name
    },
  },
  mounted() {
    this.isLogin = Token.get()
    this.Basic()
    this.getBanner(localStorage.getItem('language'))
    EventBus.$on('changeLanguage', (lang) => {
      this.getBanner(lang)
    })
    this.signalRStart()
    this.getList()
  },
  methods: {
    change() {
      let product = this.$t('product')
      this.dataModeList = [
        product.Trend,
        '1' + product.smallMin,
        '5' + product.smallMin,
        '15' + product.smallMin,
        '1' + product.hour,
        '1' + product.dayMin,
      ]
    },
    regexp: function (str) {
      let str1 = str.replace(
        new RegExp('CompanyName ', 'g'),
        this.basicConfig.CompanyName
      )
      str1 = str1.replace(
        new RegExp('CompanyNameEnglish ', 'g'),
        this.basicConfig.CompanyNameEnglish
      )
      str1 = str1.replace(
        new RegExp('CompanyNameTraditional ', 'g'),
        this.basicConfig.CompanyNameTraditional
      )
      str1 = str1.replace(
        new RegExp('CompanyNameJapanese ', 'g'),
        this.basicConfig.CompanyNameJapanese
      )
      str1 = str1.replace(
        new RegExp('CompanyFullName ', 'g'),
        this.basicConfig.CompanyFullName
      )
      str1 = str1.replace(
        new RegExp('CompanyFullNameEnglish ', 'g'),
        this.basicConfig.CompanyFullNameEnglish
      )
      str1 = str1.replace(
        new RegExp('CompanyFullNameTraditional ', 'g'),
        this.basicConfig.CompanyFullNameTraditional
      )
      str1 = str1.replace(
        new RegExp('CompanyFullNameJapanese ', 'g'),
        this.basicConfig.CompanyFullNameJapanese
      )

      return str1
    },
    async Basic() {
      let res = await productApi.Basic()
      this.productBasic = res
    },
    async getBanner(lang) {
      let LanguageType = ''
      if (lang === 'zh') {
        LanguageType = 'Chinese'
      } else if (lang === 'en') {
        LanguageType = 'English'
      } else if (lang === 'ft') {
        LanguageType = 'Traditional'
      } else {
        LanguageType = 'Japanese'
      }
      this.rotationPics = await userApi.rotationPics({ LanguageType })
    },
    showPrice(Price) {
      if (Price >= 100) {
        return Price.toFixed(2)
      } else if (Price < 100 && Price > 0.1) {
        return Price.toFixed(4)
      } else {
        return Price
      }
    },
    signalRStart() {
      let that = this
      let connection = this.connection
      let time = setInterval(() => {
        if (connection.connectionState === 'Connected') {
          clearInterval(time)
          time = null
          connection.invoke('SendMsg', 'home')
        }
      }, 100)
      connection.on('HomeSecondPrices', function (res) {
        res.forEach((item) => {
          that.productBasic.some((sItem) => {
            if (item.Id === sItem.Id) {
              item.difference = item.Price - sItem.BasicPrice
              item.difference = item.difference.toFixed(2)
              item.percentage = (item.difference / item.Price) * 100
              item.percentage = item.percentage.toFixed(2)
              return true
            }
          })
        })
        that.productList = res
      })
      connection.on('ProductNames', function (res) {
        that.ProductNames = res
      })
    },
    async getList() {
      this.proList = await productApi.list()
    },
    showProductName(ProductId) {
      let name = ''
      this.proList.forEach((item) => {
        if (item.Id === ProductId) {
          if (this.lang === 'zh') {
            name = item.Name
          } else if (this.lang === 'en') {
            name = item.EnglishName
          } else {
            name = item.TraditionalName
          }
          return
        }
      })
      return name
    },
    JumpWeb(url) {
      window.location.href = url
    },
    handPre() {
      this.$refs.swiper.prev()
    },
    handNext() {
      this.$refs.swiper.next()
    },
    toLogin() {
      this.$router.push('/login')
    },
    toRegister() {
      this.$router.push('/register')
    },
  },
  destroyed() {
    if (this.connection.connectionState === 'Connected') {
      this.connection.invoke('SendMsg', '')
    }
  },
}
</script>
<style>
.van-swipe__indicator {
  width: 10px;
  height: 10px;
}
</style>
<style lang="less" scoped>
@swipe-indicator-size: 10px;
.p-15 {
  padding: 15px;
}
.container {
  background-color: white;
  padding-top: 72px;
  .header {
    height: 72px;
    background-color: white;
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    .title {
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 90px;
      }
    }
    .lang {
      position: absolute;
      top: 50%;
      right: 20px;
      width: 16px;
      height: 16px;
      margin-left: 10px;
      padding-left: 45px;
      padding-right: 15px;
      text-align: left;
      color: #0f2c52;
      background: url(~@/assets/icon_globe.svg) no-repeat;
      background-size: 100% 100%;
      margin-top: -8px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 6px;
        width: 0;
        height: 0;
        border: 4px solid transparent;
        border-top-color: currentColor;
      }
    }
  }
  .swipe-box {
    position: relative;
    .swipe-container {
      overflow: hidden;
      height: 400px;
      .van-swipe-item {
        width: 100vw;
        overflow: hidden;
        position: relative;
        .bg {
          position: absolute;
          z-index: -1;
          left: 50%;
          transform: translate(-50%);
          max-width: none;
          width: auto;
          height: 100%;
        }
      }
    }
    .nav-pre {
      .pre {
        position: absolute;
        top: 50%;
        left: 20px;
        &::after {
          transform: rotate(-45deg);
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border: 0 #ccc solid;
          border-top-width: 2px;
          border-left-width: 2px;
        }
      }
      .next {
        position: absolute;
        top: 50%;
        right: 20px;
        &::after {
          transform: rotate(135deg);
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border: 0 #ccc solid;
          border-top-width: 2px;
          border-left-width: 2px;
        }
      }
    }
    .swiper-btn {
      background-color: #f2672a;
      color: #fff;
      border-color: #f2672a;
      min-width: 230px;
      height: 52px;
      line-height: 52px;
      font-size: 18px;
      position: absolute;
      bottom: 50px;
      left: 50%;
      margin-left: -115px;
      text-align: center;
      font-weight: bold;
      border-radius: 5px;
    }
  }
  .grid {
    padding: 0 35px;
    background-color: white;
    box-sizing: border-box;
    ul {
      display: flex;
      margin-right: -15px;
      margin-left: -15px;
      flex-wrap: wrap;
      li {
        width: 49%;
        text-align: center;
        align-items: center;
        padding-bottom: 15px;
        &:nth-child(1) {
          border-right: 1px solid #ddd;
        }
        &:nth-child(3) {
          border-top: 1px solid #ddd;
          border-right: 1px solid #ddd;
        }
        &:nth-child(4) {
          border-top: 1px solid #ddd;
        }
        h2 {
          font-size: 32px;
          font-weight: 700;
          color: #0f2c52;
          height: 59px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
        }
        h5 {
          font-size: 12px;
          line-height: 25px;
          height: 25px;
          font-weight: 400;
          margin: 0;
          margin-bottom: 6px;
        }
      }
    }
  }
  .product-box {
    border-radius: 10px;
    box-shadow: 0 3px 15px #eee;
    padding-bottom: 15px;
    .product-list {
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
      height: 95px;
      .item {
        width: 31vw;
        display: inline-block;
        text-align: center;
        padding: 0 10px;
        .top {
          font-size: 17px;
          font-weight: bold;
          color: #177bdc;
          margin: 12px 0;
        }
        .bottom {
          font-size: 0;
          .border {
            margin: 0;
          }
          .now-price {
            display: inline-block;
            color: #333;
            font-size: 14px;
            padding-bottom: 3px;
            position: relative;
            .arrow {
              position: absolute;
              right: -10px;
              top: 2px;
              display: inline-block;
              width: 6px;
              height: 10px;
              background: url('~@/assets/fourHome/download.png') 0 center
                no-repeat;
              background-size: 100% 100%;
            }
            .Rise {
              background-image: url('~@/assets/fourHome/top.png');
            }
          }
          .mini-text {
            margin-top: 7px;
            padding: 0 3px;
            font-size: 12px;
            color: #999;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
    .btn {
      color: #fff;
      background: #0c73da;
      font-size: 16px;
      width: 220px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      margin: 0px auto;
      border-radius: 30px;
    }
  }
  .desc {
    padding: 35px 15px;
    text-align: center;
    h4 {
      font-size: 16px;
      font-weight: 700;
      color: #24282d;
      margin: 20px 0 10px;
    }
    h3 {
      font-size: 16px;
      color: #0f2c52;
      display: block;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      line-height: 23px;
    }
  }
  .desc1 {
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .experience {
    background: url(~@/assets/bg_trade_anywhere_blue.jpg) center center / cover;
    padding: 80px 0;
    .content {
      padding: 0 15px;
      h2 {
        font-size: 24px;
        color: white;
        padding-top: 28px;
      }
      p {
        color: #fff;
        font-size: 16px;
        margin-bottom: 40px;
        line-height: 24px;
      }
      .btn {
        border: 1px solid white;
        text-align: center;
        width: 270px;
        font-size: 18px;
        height: 51.6px;
        line-height: 51.6px;
        font-weight: bold;
        color: white;
        border-radius: 6px;
      }
    }
  }
  .area {
    padding: 0 20px;
    padding-top: 50px;
    font-size: 24px;
    text-align: center;
    line-height: 1.5;
  }
  .honor {
    background: url(~@/assets/image_awards_bg.jpg) center center / cover
      no-repeat;
    padding: 50px 0;
    text-align: center;
    .swipe-container {
      height: 245px;
    }
    .van-swipe-item {
      height: 205px;
    }
    h2 {
      text-align: center;
      color: white;
      font-size: 24px;
    }
    .pic {
      img {
        width: 100px;
      }
    }
    .caption {
      h4 {
        font-size: 16px;
        color: white;
        font-weight: 400;
        margin-top: 5px;
        margin-bottom: 8px;
      }
      h5 {
        margin-top: 10px;
        font-size: 14px;
        color: white;
        font-weight: 400;
        line-height: 25px;
      }
    }
  }
  .pay {
    padding: 50px 20px;
    text-align: center;
    h2 {
      font-size: 24px;
      line-height: 28px;
    }
    p {
      margin-bottom: 50px;
      font-size: 16px;
      line-height: 24px;
    }
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .list {
        text-align: center;
        flex: 0 0 50%;
        max-width: 50%;
        height: 52px;
        img {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
  .black-bg {
    background-color: #24282d;
    padding: 0 20px;
    .m-t0 {
      margin-top: 0 !important;
    }
    .committed {
      padding: 50px 0px;
      padding-bottom: 20px;
      color: #eee;
      font-size: 12px;
      position: relative;
      text-align: center;
      h3 {
        font-size: 18px;
        line-height: 24px;
      }
      > p {
        font-size: 12px;
        line-height: 18px;
        padding: 0 10px;
        a {
          color: white;
        }
        margin-bottom: 30px;
      }
      .getting-started-parent {
        background-color: #282e33;
        border-bottom: 1px solid #3e444b;
        margin-bottom: 30px;
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        padding: 0 20px;
        .getting-started--child {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 20px 0;
          .getting-text--child {
            margin-bottom: 10px;
            h4 {
              font-size: 20px;
              margin-bottom: 5px;
              margin-top: 10px;
            }
          }
          p {
            width: 240px;
            margin: 0 auto;
            background: #f2672a;
            color: #fff;
            border: 1px solid #f2672a;
            height: 51.6px;
            font-size: 18px;
            line-height: 51.6px;
            font-weight: bold;
          }
        }
      }
    }
    .information {
      padding-bottom: 20px;
      .information-title {
        text-align: center;
        font-size: 12px;
        color: white;
        padding-top: 5px;
      }
      .information-con {
        font-size: 12px;
        color: white;
        margin-top: 20px;
        line-height: 18px;
      }
    }
    .warn {
      background-color: #24282d;
      .content {
        background-color: #282e33;
        border-left: 8px solid #f2672a;
        font-size: 11px;
        color: #eee !important;
        line-height: 1.55;
        padding: 10px 10px 10px 21px;
        margin: 0 0 20px;
        a {
          color: white;
          text-decoration: underline;
        }
      }
    }
    .border-line {
      border-bottom: 1px solid #3e444b;
      margin: 30px 0 30px;
    }
  }
  .copyright {
    font-size: 12px;
    color: white;
    text-align: center;
    padding-bottom: 80px;
    ul {
      display: inline-block;
      margin-top: 15px;
      li {
        display: inline-block;
        border-left: 1px solid #3e444b;
        line-height: 18px;
        &:first-child {
          border-left: 0;
        }
        span {
          padding: 10px;
          color: white;
        }
      }
    }
  }
}
</style>
